.otpContainer {
    padding: 0;
    margin: 0;
    display: grid;
    place-items: center;
    margin-top: 10vh;
    text-align: center;
}

.otp {
    margin-top: 30px;
    width: 18rem;
}

.sentContainer {
    display: grid;
    place-items: center;
}

.resendLink {
    color: black;
    text-decoration: none;
    margin: 20px 0;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid black;
}

.submitBtn {
    background: #004932;
    border: none;
    outline: none;
    width: 10rem;
    border-radius: 10px;
    height: 2.5rem;
    color: #fff;
}

.submitBtn:hover, .submitBtn:active, .submitBtn::selection, .submitBtn::after  {
    background: #006545;
    color: #fff;
}

.resendLink:hover {
    color: rgb(81, 81, 81);
}

.btn:active, .btn:focus {
    box-shadow: 0 0 0 0.05rem #000;
}

h1 {
    color: #004932;
    font-weight: bold;
    margin-bottom: 50px;
}

.errorLabel {
    color: red;
    font-size: 15px;
}
