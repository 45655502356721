.outermost-div {
    padding: 0 6%;
}

.first-div {
    text-align: center;
}
.form-heading {
    color: #004932;
}

.main-label {
    font-weight: 700;
}

.outlet-input {
    border: none;
    border-radius: 10px;
    padding-left: 15px;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    outline: none;
}

.outlet-input:focus {
    border: 1.5px solid #00865b;
}

.submit-div {
    padding: 2% 0;
    text-align: center;
}

.submit-btn {
    background-color: #004932;
    color: white;
}

.required::after {
    content: " *";
    color: red;
}

::placeholder {
    padding-left: 10px;
    font-size: small;
    color: rgb(197, 195, 195);
}

.delete-div {
    margin-top: 2%;
    margin-left: 15%;
    margin-right: 15%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: 1px solid red;
    border-radius: 10px;
}

.delete-txt-div {
    text-align: left;
    margin-left: 2%;
}

.delete-txt {
    margin-top: 2%;
}

.delete-btn-div {
    text-align: right;
    margin-right: 2%;
}

.delete-btn {
    background-color: red;
    color: white;
}

.submit-btn:hover {
    background-color: #017450;
    color: white;
}

.delete-btn:hover {
    background-color: rgb(249, 90, 90);
    color: white;
}

.no-outlet {
    text-align: center;
}

.img-outlet {
    width: 50%;
}

.no-outlet-text {
    color: #004932;
}
