/* body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}

main {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

.dropdown-toggle {
    outline: 0;
}

.sidebar {
    height: 100vh;
    position: sticky;
    top: 0;
}

.sidebar-wrapper {
    padding: 0;
    position: sticky;
    top: 0;
}

.nav-link {
    color: black;
}

.nav-link:hover, .nav-link:active, .nav-link:focus {
    color: rgb(83, 83, 83);
}

.nav-pills .nav-link.active{
    background-color: #004932;
}

.ownerName:hover {
    color: rgb(92, 92, 92);
}

.dropdown-toggle:hover {
    color: black;
}
.ul{
    padding-top: 10%;
} */

/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 78px;
    background: #ffffff;
    padding: 6px 14px;
    z-index: 99;
    transition: all 0.5s ease;
}
.sidebar.open {
    width: 250px;
}
.sidebar .logo-details {
    height: 48px;
    display: flex;
    background-color: #ffffff;
    align-items: center;
    position: relative;
}
.sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
    color: #004932;
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
    opacity: 1;
}
.sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
    text-align: right;
}
.sidebar i {
    color: #000000;
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
}
.sidebar .nav-list {
    margin-top: 20px;
    height: 100%;
}
.sidebar li {
    position: relative;
    margin: 8px 0;
    right: 12px;
    list-style: none;
}
.sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
}
.sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
}
.sidebar.open li .tooltip {
    display: none;
}
.sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: #ffffff;
}
.sidebar li a:hover {
    background: #004932;
}
.sidebar li a .links_name {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}
.sidebar.open li a .links_name {
    opacity: 1;
    pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
    transition: all 0.5s ease;
    color: #ffffff;
}
.arrow {
    transition: transform 0.5s;
    transform: rotate(0deg); /* This is the base state */
}
.arrow-up {
    transition: transform 0.5s; 
    transform: rotate(180deg);
}
.dropdown-item-list{
    height: 2rem;
    border-radius: 10px;
    background-color: transparent;
}
.dropdown-item:focus, .dropdown-item:active {
    background-color: transparent;
    border-radius: 10px;
}
.dropdown-item:hover {
    color: white;
    border-radius: 10px;
}
.sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
}
.sidebar li.profile {
    position: fixed;
    height: 60px;
    width: 78px;
    left: 0;
    bottom: -8px;
    padding: 10px 14px;
    background: #f7f7f7;
    transition: all 0.5s ease;
    overflow: hidden;
}
.sidebar.open li.profile {
    width: 250px;
}
.sidebar li .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.sidebar li img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    white-space: nowrap;
}
.sidebar li.profile .job {
    font-size: 12px;
}
.sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #f7f7f7;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
    width: 50px;
    background: none;
}
.home-section {
    position: relative;
    background: #e4e9f7;
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
}
.sidebar.open ~ .home-section {
    left: 250px;
    width: calc(100% - 250px);
}
.home-section .text {
    display: inline-block;
    color: #11101d;
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
}
@media (max-width: 420px) {
    .sidebar li .tooltip {
        display: none;
    }
}
