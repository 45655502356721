.orderCard {
    width: 20rem;
    border: 1px solid black;
}

.orderitem {
    margin: 0;
}

.actionBtns {
    width: 8.5rem;
}

.itemsHeading {
    padding: 0 16px;
}

.confirmBtn {
    background-color: #73a288;
    color: white;
}

.confirmBtn:hover {
    color: white;
    background-color: #87bea0;
}

.rejectBtn {
    background-color: #f95a5a;
    color: white;
}

.rejectBtn:hover {
    background-color: #f68585;
    color: white;
}

.leftItems, .leftItems:focus, .leftItems:active {
    border: none;
    color: #004932;
    outline: none;
    box-shadow: 0 0 0 0;
}

.leftItems:hover {
    color: #036e4c;
}

.modal-content {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.reasons {
    padding: 0;
}

.Checkboxes {
    padding: 0 15%;
}

.rejectSubmit {
    background: #004932;
    color: white;
    border-radius: 10px;
    width: 8rem;
}

.rejectSubmit:hover {
    background-color: #036e4c;
    color: white;
}

.radioBtn:checked, .radioBtn:active {
    background-color: #004932;
    box-shadow: 0 0 0 0;
    outline: none;
    border: none;
}

.itemCheckbox {
    width: 15px;
    height: 15px;
    accent-color: #004932;
}

.errorLabel1 {
    color: red;
    font-size: 15px;
    margin-top: 10px;
}