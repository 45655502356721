.ownerDetails {
    width: 90%;
}

.inputText {
    border: 1px solid rgb(239, 238, 238);
    margin: 0.5rem 0;
    width: 100%;
    height: 3.3rem;
    border-radius: 10px;
    padding-left: 15px;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    outline: none;
}

.inputText:focus {
    border: 1.5px solid #00865b;
}

::placeholder {
    padding-left: 10px;
    font-size: small;
}

.inputLabel {
    padding-top: 20px;
}

.saveChanges {
    background-color: #004932;
    color: white;
    width: 12rem;
    border-radius: 10px;
}

.saveChanges:hover {
    background-color: #00865b;
    color: white;
}

.iconVerify {
    margin-left: -5px;
}

.errorLabelPass {
    color: red;
    font-size: medium;
}