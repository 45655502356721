.mainDiv {
    display: grid;
    place-items: center;
}

.inputText1 {
    border: 1px solid rgb(239, 238, 238);
    margin: 0.5rem 0;
    width: 55vh;
    height: 3.3rem;
    border-radius: 10px;
    padding-left: 15px;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    outline: none;
}

.inputText1:focus {
    border: 1.5px solid #00865b;
}

::placeholder {
    padding-left: 10px;
    font-size: small;
}

.formDiv {
    width: 55vh;
}