.orderPartition {
    margin-top: -20px;
    margin-right: 15px;
    height: 85vh;
    border-radius: 10px;
    overflow-y: auto;
    border: 0.5px solid #e7e7e7;
}

.orderContainer {
    width: 105%;
}

.orderHeading {
    margin-top: 15px;
    color: #004932;
}
