.bg {
    background-image: url("../images/green.jpeg");

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    width: 20rem;
    margin-top: 50px;
}

.glass{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    width: 50%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    padding-bottom: 40px;
    border:1px solid rgba(255, 255, 255, 0.18);
}

.blur {
    text-align: center;
    width: 100%;
    backdrop-filter: blur(5px); /* blur effect to the backdrop */
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 30px;
    height: 100vh;
}

.authBtn {
    color: black;
    background-color: white;
    width: 8rem;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 3rem;
    box-shadow: 0 0 0 0;
}

.authBtn:hover {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    outline: none;
    box-shadow: 0 0 0 0;
}

@media (max-width: 800px)
{
    .logo {
        width: 10rem;
    }

    .glass {
        width: 80%;
    }
}