.main-div {
    width: 100%;
    margin-top: 0;
}

hr {
    color: #000;
    width: 100%;
}

.categoryHead {
    font-weight: 600;
    font-size: 25px;
    margin-right: 10px;
}

.categoryScroll {
    display: flex;
    flex-wrap: nowrap;
}

.categoryContainer {
    width: 100%;
    float: none;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.editIcon {
    width: 30px;
    height: 30px;
    padding-bottom: 20px;
}

.editIcon:hover {
    color: #545454;
}

.categoryName {
    display: inline;
}

.categoryButton {
    flex: 0 0 auto;
    margin: 10px 0;
}

.plus {
    height:1rem;
    width: 1rem;
    padding-top: 6.5px;
}

.plus:hover {
    color: #11bb85;
}

.productUpdel {
    margin-top: 7px;
}