.add-new {
    color: white;
    background-color: #004932;
    font-size: medium;
}

.header-sec {
    padding-bottom: 4%;
}

.inner-div {
    padding: 6% 0%;
}

.modal-head {
    font-weight: 600;
}

.add-new:hover {
    background-color: #017450;
    color: white;
}

.p-margin {
    margin-right: 25px;
}

.outer-div {
    padding-top: 5%;
}
